
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'

function VideoComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [vidSrc,setVidSrc] = useState(0)
    
    let bLoaded=false
    const refEle = useRef()
    const refVid = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    // eslint-disable-next-line no-unused-vars
    const [isAutoplay, setIsAutoplay] = useState(true);
    const [vidStyle,setVidStyle] = useState({width:"100%",height:"100%",objectFit:props.fit?props.fit:'cover'})

    // useEffect(()=>{ 
    //     refOrigOffset.current=refEle.current.offsetLeft
    //     if(refFormat.current && refFormat.current.animation){
    //         refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
    //     }
       
    // },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    // const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
    //     animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    // },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    // const onIntersect=  useCallback((evt)=>{
    //     if(evt.detail.isIntersecting )
    //     {
    //         if(evt.detail.intersectionRatio >=0.5)
    //            refVid.current.play()
    //     }
    //     else if(evt.detail.intersectionRatio <=0)
    //         refVid.current.pause()
    // },[])

    // useEffect(()=>{
        
    //     refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)

    //     let style ={position:'absolute',transform: 'translateZ(0)',willChange:'transform'}
    //     style.position='absolute'

    //     if(refFormat.current.style)
    //     {
    //         style = Object.assign(style,refFormat.current.style)
    //     }
    //     setInline(style)
    //     setVidSrc(getContentPath(refFormat.current.file))

        
    //     //set up animation Type
    //     if(refFormat.current.animation){
    //         if(refFormat.current.animation.typeName==='parallax'){
    //             props.setScrollCallback(onScroll,props.index)
    //             style.willChange='transform'
    //         } 
    //         else if(refFormat.current.animation.typeName==='page'){
    //             props.onIntersect(onIntersect,props.index)
    //             style.willChange='transform, scale, opacity'
    //         }
    //     }


    //     return ()=>{
        
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])

    

    // async function onLoad(){
    //     //down res the image
    //     if(!bLoaded){
    //         props.onLoad(props.index,refEle.current,props.model)
    //         bLoaded=true
    //     }
        
    // }

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
        
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        // console.log(refAnimationModel.current)
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt,threshold)=>{
        console.log('refFormat.current', refFormat.current)
        if (refFormat.current.loop) {
            refVid.current.loop = true
        } 

        // console.log('onINTERSECT')
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=threshold)
                animateIntersect(refEle.current,refAnimationModel.current)  
            if(evt.detail.intersectionRatio >=0.5)
                if (refFormat.current.autoplay) {
                    refVid.current.play()
                    console.log('play')
                }
              
            
        }
        else if(evt.detail.intersectionRatio <=0.001) {
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
            if (refVid.current.currentTime > 0) {
                refVid.current.pause()
                refVid.current.currentTime = 0;
            }
            
            }
        else if(evt.detail.intersectionRatio <=0)
            if (refVid.current.currentTime > 0) {
                refVid.current.pause()
                refVid.current.currentTime = 0;
            }
            console.log('pause')
    


    },[refEle, refVid])

    useEffect(()=>{
        // console.log(props.model,props.format)
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform'}

        if (refFormat.current) {
            if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        
            let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
            // console.log("seting ",getContentPath(f))

            console.log('refFormat', refFormat.current, props.model.formats,)

            // console.log("FORMAT", refFormat.current, props.model.formats, props.format, f)

            setVidSrc(getContentPath(f))
            
            //set up animation Type
            if(refFormat.current.animation ){
                if(refFormat.current.animation.typeName==='parallax'){
                    props.setScrollCallback(onScroll,props.index)
                    style.willChange='transform'
                } 
                else if(refFormat.current.animation.typeName==='page'){
                    props.onIntersect(onIntersect,props.index)
                    style.willChange='transform, scale, opacity'
                }
            }

            
        }
        console.log('style', props.model.formats, Globals.instance().getCanvasFormat(props.model.formats,props.format))
        setInline(style)
        
        
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // useEffect(() => {
    //     console.log('props.action', props.action, props)
    //     if (props.action) {
    //         if (props.action.command === "video") {
    //             if (props.active.detail === "play") {
    //                 refVid.current.play();
    //             }
    //             if (props.active.detail === "pause") {
    //                 refVid.current.pause();
    //             }
    //         }
    //     }

    // }, [props])

    function onError(evt){
        console.error("PNG load error"+bLoaded, evt.target.src)
        // onErrorLoad(evt)
        console.log("src "+evt.target.src)
        if(!bLoaded && vidSrc!==""){
            Globals.instance().trackError('PNG Component '+vidSrc+' ',false)
            onLoad() //dont hold up the page 
        }
    }
    
    function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true

            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

         

        }
        
    }

    function onClickVideo(e) {
        props.onClickElem('video', e.target, null)
        
    }
    
    return ( 
        <div style={inline} ref={refEle}  >

        {refFormat.current && 
        
           (refFormat.current.autoplay ? <video style={vidStyle} onClick={(e) => onClickVideo(e)} ref={refVid} muted playsInline autoPlay src={vidSrc} type="video/mp4" id={'video-' + refFormat.current.id} onLoadedMetadata={onLoad}>
                <source src={vidSrc} type="video/mp4"></source>
            </video> : <video style={vidStyle} id={'video-' + refFormat.current.id} onClick={(e) => onClickVideo(e)} ref={refVid} playsInline src={vidSrc} type="video/mp4" onLoadedMetadata={onLoad}>
            <source src={vidSrc} type="video/mp4"></source>
        </video>)
        
             }
        </div>
        
    )

}

const VIDEO = memo( VideoComponent ,(prev,next)=>{ 
    return prev.imgSrc === next.imgSrc
})

export  {VIDEO}
